import React from 'react';
import AdminPostList from '../../components/AdminPostList';

function AdminPostListPage() {
    return (
        <div className="admin-post-list-page">
            <AdminPostList/>
        </div>
    );
}

export default AdminPostListPage;
