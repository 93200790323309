import React from 'react';
import Signup from '../components/Signup';

function SignupPage() {
    return (
        <div className="signup-page">
            <Signup />
        </div>
    )
}

export default SignupPage;
